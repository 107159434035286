@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  font-family: 'NeueHaasDisplay', Arial;
  -webkit-font-smoothing: antialiased;
}

body {
  background: #EDEDED;
  color: #252525;
}

@screen ms {
  html {
    font-size: 18px;
  }
}

@screen mm {
  html {
    font-size: 18px;
  }
}

@screen ml {
  html {
    font-size: 18px;
  }
}

@screen t {
  html {
    font-size: 18px;
  }
}

@screen dxs {
  html {
    font-size: 16px; 
  }
}

@screen ds {
  html {
    font-size: 16px;
  }
}

@screen dm {
  html {
    font-size: 18px;
  }
}

@screen dl {
  html {
    font-size: 18px;
  }
}

@screen dxl {
  html {
    font-size: 20px;
  }
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('./resources/fonts/NeueHaasDisplay-Thin.woff2') format('woff2'),
       url('./resources/fonts/NeueHaasDisplay-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('./resources/fonts/NeueHaasDisplay-Light.woff2') format('woff2'),
       url('./resources/fonts/NeueHaasDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('./resources/fonts/NeueHaasDisplay-Roman.woff2') format('woff2'),
       url('./resources/fonts/NeueHaasDisplay-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('./resources/fonts/NeueHaasDisplay-Medium.woff2') format('woff2'),
       url('./resources/fonts/NeueHaasDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('./resources/fonts/NeueHaasDisplay-Bold.woff2') format('woff2'),
       url('./resources/fonts/NeueHaasDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Roboto Mono';
  src: url('./resources/fonts/RobotoMono-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RPBrawl';
  src: url('./resources/fonts/RPBRAWL-Regular.woff2') format('woff2'),
       url('./resources/fonts/RPBRAWL-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  /* .cardGradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #F7F7F7;
  } */
  .defaultButtonStyles {
    box-shadow: 0px 36px 33px rgba(13, 52, 89, 0.06), 
                0px 18px 18px rgba(13, 52, 89, 0.04), 
                0px 6px 10.0172px rgba(13, 52, 89, 0.04);
  }
  .grButtonStyles {
    background: linear-gradient(180deg, rgba(124, 138, 152, 0) 0%, rgba(124, 138, 152, 0.15) 100%);
    box-shadow: 0px 25px 33px rgba(13, 52, 89, 0.06), 0px 18px 18px rgba(13, 52, 89, 0.04);
  }

  .cardShadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08), 
                0px 11px 15px rgba(65, 113, 185, 0.06), 
                0px 30px 40px rgba(65, 113, 185, 0.03);
  }

  .deligationPoolBgHover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.77), rgba(255, 255, 255, 0.77)), 
                linear-gradient(270.14deg, #AFFFCF 0.16%, #D2E1FF 54.4%, #FAFBFF 103.11%);
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08), 
                0px 11px 15px rgba(65, 113, 185, 0.06), 
                0px 30px 40px rgba(65, 113, 185, 0.03);
  }
  /* .cardBorder {
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, #DAE3F0 100%);
  } */

  .shadowBlue {
    box-shadow: 0px 40px 49px rgba(65, 113, 185, 0.21), 
                0px 15.4074px 15.6074px rgba(65, 113, 185, 0.127556), 
                0px 3.25926px 3.99259px rgba(65, 113, 185, 0.0824444);
  }
  .no-scrollbar {
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }  

  .deligationPoolBgShadow {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), 
                rgba(255, 255, 255, 0.5)), #E9ECF0;
    box-shadow: 0px 40px 49px rgba(65, 113, 185, 0.21), 
                0px 15.4074px 15.6074px rgba(65, 113, 185, 0.127556), 
                0px 3.25926px 3.99259px rgba(65, 113, 185, 0.0824444);
  }
}


.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #5094FC;
  }
  40% {
    stroke: #5094FC;
  }
  66% {
    stroke: #5094FC;
  }
  80%, 90% {
    stroke: #5094FC;
  }
}
@keyframes color {
  0% {
    stroke: #5094FC;
  }
  40% {
    stroke: #5094FC;
  }
  66% {
    stroke: #5094FC;
  }
  80%, 90% {
    stroke: #5094FC;
  }
}

.recharts-tooltip-wrapper {
  border: none;
  outline: none;
}


